import {
  sendDataToFireBase,
  IFirebaseConfig,
} from "@push-notifications/firebase";
import { useEffect, useRef } from "react";

const firebaseProps: IFirebaseConfig = {
  firebaseConfig: {
    apiKey: process.env.REACT_APP_API_KEY ?? "",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN ?? "",
    projectId: process.env.REACT_APP_PROJECT_ID ?? "",
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET ?? "",
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID ?? "",
    appId: process.env.REACT_APP_APP_ID ?? "",
  },
  validKey: process.env.REACT_APP_PUBLIC_VAPID_KEY ?? "",
  baseURL: process.env.REACT_APP_BASE_URL ?? "/",
};

export const usePresenter = () => {
  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      sendDataToFireBase(firebaseProps);
    }

    return () => {
      effectRan.current = true;
    }
  }, []);
};
